import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import BlogList from '../components/blogList'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from '../lib/helpers'
import Hero from '../components/subPageHero'

import heroBackground from "../assets/images/hero-background.jpg"

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query BlogPageQuery {
    posts: allSanityPost(
      limit: 100
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              _id
            }
            alt
          }
          thumbImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const BlogPage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const postNodes =
    data && data.posts && mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout>
      <SEO title='Blog' />
      <Container>
        <Hero heroBackground={{heroBackground}} heroText={'My Musings'} />

        <h1 className={responsiveTitle1}></h1>
        {postNodes && postNodes.length > 0 && <BlogList nodes={postNodes} />}
      </Container>
    </Layout>
  )
}

export default BlogPage
