import React from 'react'
import {Link} from 'gatsby'
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'

import styles from './project-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function PostPreview (props) {
  console.log(props)
  const {publishedAt} = props
  const dateSlug = format(publishedAt, 'YYYY/MM');
  return (
        <Link className={styles.root} to={`/${dateSlug}/${props.slug.current}`}>
          <div className={styles.card}>
            <div className={styles.leadMediaThumb}>
              {props.thumbImage && props.thumbImage.asset && (
                <img
                  src={imageUrlFor(buildImageObj(props.thumbImage))
                    .width(600)
                    .height(Math.floor((9 / 16) * 600))
                    .url()}
                  alt={props.thumbImage.alt}
                />
              )}
            </div>
            <div className={styles.contentContainer}>
              <h6 className={styles.subHead}>
                {format(new Date(publishedAt), 'MMMM Do YYYY')}

              </h6>
              <h3 className={cn(responsiveTitle3, styles.title)}>{props.title}</h3>
              {props._rawExcerpt && (
                <div className={styles.excerpt}>
                  <BlockText blocks={props._rawExcerpt} />
                </div>
              )}
            </div>
          </div>
        </Link>

  )
}

export default PostPreview
